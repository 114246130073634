import { useCurrentPath } from "utils/Router"

const usePageTitle = () => {
    const currentPath = useCurrentPath() ?? []

    if (currentPath.length !== 0) {
        return null
    }
    const title = currentPath[currentPath.length - 1].route.title!
        ? currentPath[currentPath.length - 1]?.route.title
        : null
    return title
}

export default usePageTitle
