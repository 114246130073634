import { createSearchParams, useNavigate, useSearchParams } from "react-router-dom"

const ReloadPage = () => {
    let [searchParams] = useSearchParams()
    const navigate = useNavigate()

    if (searchParams.get("return")) {
        navigate({
            pathname: searchParams.get("return")!,
            search: createSearchParams({ asd: Math.random().toString() }).toString(),
        })
    } else {
        navigate({
            pathname: "Login",
            search: createSearchParams({ asd: Math.random().toString() }).toString(),
        })
    }

    return <div></div>
}

export default ReloadPage
