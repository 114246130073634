import { createRoot } from "react-dom/client"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"

import { Button } from "@mui/material"
import i18n from "i18n"
import { enqueueSnackbar } from "notistack"
import "./Map.css"

const container = document.getElementById("root")
const root = createRoot(container!)
root.render(<App />)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA

// serviceWorker.register({
//     onUpdate: (registration) => {
//         if (registration.waiting) {
//             store.notification.enqueue({
//                 message: i18n.t("common.errors.newVersion"),
//                 options: {
//                     variant: 'info',
//                     persist: true,
//                     preventDuplicate: true,
//                     action: (<Button size="small" onClick={() => {

//                         registration.waiting!.postMessage({ type: 'SKIP_WAITING' })
//                         window.location.reload()

//                     }} >
//                         {i18n.t("common.updateNow")}
//                     </Button>)
//                 }
//             })
//         }
//     }
// });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
    onUpdate: (registration) => {
        if (registration.waiting) {
            enqueueSnackbar(i18n.t("common.errors.newVersion"), {
                variant: "info",
                persist: true,
                preventDuplicate: true,
                action: (
                    <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                            registration.waiting!.postMessage({ type: "SKIP_WAITING" })
                            window.location.reload()
                        }}
                    >
                        {i18n.t("common.updateNow")}
                    </Button>
                ),
            })
        }
    },
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
