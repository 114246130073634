import { matchRoutes, useLocation } from "react-router-dom"
import routes from "routes"
/**
 * aktif konumun Route ağazını getirir
 */
export const useCurrentPath = () => {
    const location = useLocation()
    const route = matchRoutes(routes, location)

    return route
}
