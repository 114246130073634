function tryParseInt(str, defaultValue: number) {
    let retValue = defaultValue
    if (str !== null) {
        if (str.length > 0) {
            if (!isNaN(str)) {
                retValue = parseInt(str)
            }
        }
    }
    return retValue
}

export default tryParseInt
