import { Grid } from "@mui/material"
import { Plus } from "mdi-material-ui"
import { useTranslation } from "react-i18next"
import ButtonWithPermission from "./ButtonWithPermission"

interface IAddButtonProps {
    handleAddModalOpen: React.MouseEventHandler<HTMLButtonElement>
    disabled?: boolean
    label?: string
    permission: string
}
const AddButton: React.FC<IAddButtonProps> = (props) => {
    const { t } = useTranslation()
    const { disabled = false, handleAddModalOpen, label = t("common.add"), permission } = props

    return (
        <Grid item>
            <ButtonWithPermission
                permission={permission}
                size="small"
                startIcon={<Plus />}
                variant="outlined"
                color="primary"
                disabled={disabled}
                onClick={handleAddModalOpen}
            >
                {label!}
            </ButtonWithPermission>
        </Grid>
    )
}

export default AddButton
