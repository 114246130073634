import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
    BlockUserRequest,
    GetIsGrantedRequest,
    GetRoleNamesRequest,
    GetUserListRequest,
    GetUserNameListRequest,
    GetUserRequest,
    GetUserStatusCountRequest,
    OwnUserSettingsUpdateDto,
    PermissionDto,
    UpdateUserAvatarRequest,
    UploadResultDTO,
    UserAddDto,
    UserListDto,
    UserNameListDto,
    UserRequestPasswordDto,
    UserResetPasswordDto,
    UserUpdateDto,
    useUserApi,
} from "api"
import { UserStatusCountDto } from "api/models/UserStatusCountDto"
import { StoreUseQueryOptions } from "types"

export const userKeys = {
    all: ["user"] as const,
    lists: () => [...userKeys.all, "list"] as const,
    statusCount: (param: GetUserStatusCountRequest) => [...userKeys.lists(), "statusCount", param] as const,
    list: (param: GetUserListRequest) => [...userKeys.lists(), param] as const,
    roleNames: (param: GetRoleNamesRequest) => [...userKeys.lists(), param] as const,
    nameList: (param: GetUserNameListRequest) => [...userKeys.lists(), "name", param] as const,
    details: () => [...userKeys.all, "detail"] as const,
    detail: (param: GetUserRequest) => [...userKeys.details(), param] as const,
    documents: () => [...userKeys.all, "document"] as const,

    permissions: () => [...userKeys.all, "permission"] as const,
    permission: (param: GetIsGrantedRequest) => [...userKeys.permissions(), param] as const,

    userAuthTypes: () => [...userKeys.all, "userAuthType"] as const,
    userAuthType: (param: GetUserRequest) => [...userKeys.userAuthTypes(), param] as const,
}

export const useGetUser = (
    options: StoreUseQueryOptions<UserListDto> = {
        notifyOnChangeProps: ["data", "isFetching", "isFetching"],
    }
) => {
    const userApi = useUserApi()

    return useQuery({
        queryKey: ["userInfo"],

        queryFn: ({ queryKey }) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [_key, _params] = queryKey
            return userApi.getOwnUser()
        },

        ...options,
    })
}

export const useUserRoleNamesList = (
    param: GetRoleNamesRequest,
    options: StoreUseQueryOptions<Array<string>> = {
        placeholderData: [],
        notifyOnChangeProps: ["data", "isFetching", "isFetching"],
    }
) => {
    const userApi = useUserApi()

    return useQuery({
        queryKey: userKeys.roleNames(param),
        queryFn: () => userApi.getRoleNames(param),
        ...options,
    })
}

export const useUpdateUserMutation = () => {
    const userApi = useUserApi()
    const queryClient = useQueryClient()

    return useMutation<void, any, OwnUserSettingsUpdateDto, any>({
        mutationFn: (d) => userApi.putOwnUserSettings({ ownUserSettingsUpdateDto: d }),

        onSettled: (_data, error, variables) => {
            if (!error || error.status !== 400) {
                queryClient.invalidateQueries({
                    queryKey: ["userInfo"],
                })
            }
        },
    })
}

export const useUpdateUserPasswordMutation = () => {
    const userApi = useUserApi()
    const queryClient = useQueryClient()

    return useMutation<void, any, OwnUserSettingsUpdateDto, any>({
        mutationFn: (d) => userApi.putOwnPassword({ ownUserPasswordUpdateDto: d }),

        onSettled: (_data, error, variables) => {
            if (!error || error.status !== 400) {
                queryClient.invalidateQueries({
                    queryKey: ["userPassword"],
                })
            }
        },
    })
}
export const useUserNameList = (
    param: GetUserNameListRequest,
    options: StoreUseQueryOptions<UserNameListDto[]> = {
        placeholderData: [],
        notifyOnChangeProps: ["data", "isFetching", "isFetching"],
    }
) => {
    const userApi = useUserApi()

    return useQuery({
        queryKey: userKeys.nameList(param),
        queryFn: () => userApi.getUserNameList(param),
        ...options,
    })
}

export const useUserList = (
    param: GetUserListRequest,
    options: StoreUseQueryOptions<UserListDto[]> = {
        placeholderData: [],
        notifyOnChangeProps: ["data", "isFetching", "isFetching"],
    }
) => {
    const userApi = useUserApi()

    return useQuery({
        queryKey: userKeys.list(param),
        queryFn: () => userApi.getUserList(param),
        ...options,
    })
}

export const useUser = (
    id: number,
    options: StoreUseQueryOptions<UserListDto> = {
        notifyOnChangeProps: ["data", "isFetching", "isFetching"],
    }
) => {
    const userApi = useUserApi()
    const param: GetUserRequest = { id }
    return useQuery({
        queryKey: userKeys.detail(param),
        queryFn: () => userApi.getUser(param),
        ...options,
    })
}

export const useUpdateUserCardMutation = () => {
    const userApi = useUserApi()
    const queryClient = useQueryClient()

    return useMutation<void, any, UserUpdateDto, any>({
        mutationFn: (d) => userApi.putUser({ id: d.id!, userUpdateDto: d }),

        onSettled: (_data, error, variables) => {
            if (!error || error.status !== 400) {
                queryClient.invalidateQueries({ queryKey: userKeys.lists() })
                queryClient.invalidateQueries({ queryKey: userKeys.detail({ id: variables.id! }) })
            }
        },
    })
}
export const useDeleteUserMutation = () => {
    const api = useUserApi()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (d: number) => api.deleteUser({ id: d }),

        // Always refetch after error or success:
        onSettled: () => {
            queryClient.invalidateQueries({ queryKey: userKeys.lists() })
        },
    })
}
export const useAddUserMutation = () => {
    const userApi = useUserApi()
    const queryClient = useQueryClient()

    return useMutation<UserListDto, any, UserAddDto, any>({
        mutationFn: (d) => userApi.postUser({ userAddDto: d }),

        onSettled: (_data, error, _variables) => {
            if (!error || error.status !== 400) {
                queryClient.invalidateQueries({ queryKey: userKeys.lists() })
            }
        },
    })
}

export const useUserStatusCount = (
    param: GetUserStatusCountRequest,
    options: StoreUseQueryOptions<UserStatusCountDto> = {
        initialData: { active: 0, passive: 0 },
        notifyOnChangeProps: ["data", "isFetching", "isFetching"],
    }
) => {
    const userApi = useUserApi()
    return useQuery({
        queryKey: userKeys.statusCount(param),
        queryFn: () => userApi.getUserStatusCount(param),
        ...options,
    })
}

export const useIsGranted = (
    param: GetIsGrantedRequest,
    options: StoreUseQueryOptions<PermissionDto> = {
        initialData: {},
        notifyOnChangeProps: ["data", "isFetching", "isFetching"],
    }
) => {
    const userApi = useUserApi()
    return useQuery({
        queryKey: userKeys.permission(param),
        queryFn: () => userApi.getIsGranted(param),
        ...options,
    })
}

export const useUpdateUserAvatarMutation = () => {
    const userApi = useUserApi()
    const queryClient = useQueryClient()

    return useMutation<UploadResultDTO, any, UpdateUserAvatarRequest, any>({
        mutationFn: (d) => userApi.updateUserAvatar(d),

        onSettled: (_data, error, variables) => {
            if (!error || error.status !== 400) {
                queryClient.invalidateQueries({ queryKey: userKeys.lists() })
                queryClient.invalidateQueries({ queryKey: userKeys.detail({ id: variables.id! }) })
            }
        },
    })
}
export const useSendUserPasswordMutation = () => {
    const userApi = useUserApi()
    return useMutation({
        mutationFn: (d: number) => userApi.sendUserPassword({ id: d }),
    })
}
export const useBlockeUserMutation = () => {
    const userApi = useUserApi()
    const queryClient = useQueryClient()

    return useMutation({
        mutationFn: (d: BlockUserRequest) => userApi.blockUser(d),

        onSettled: (_data, _error, variables) => {
            queryClient.invalidateQueries({ queryKey: userKeys.lists() })
            queryClient.invalidateQueries({ queryKey: userKeys.detail({ id: variables.id! }) })
            queryClient.invalidateQueries({ queryKey: userKeys.statusCount({}) })
        },
    })
}

export const useForgotPasswordMutation = () => {
    const userApi = useUserApi()

    return useMutation<void, any, UserRequestPasswordDto, any>({
        mutationFn: (d) => userApi.postForgotPassword({ userRequestPasswordDto: d }),
    })
}

export const useResetPasswordMutation = () => {
    const userApi = useUserApi()

    return useMutation<void, any, UserResetPasswordDto, any>({
        mutationFn: (d) => userApi.postResetPasswordWithPin({ userResetPasswordDto: d }),
    })
}
