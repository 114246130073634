import { Tabs, TabsProps } from "@mui/material"
import React from "react"
import { useSearchParams } from "react-router-dom"
import tryParseInt from "utils/tryParseInt"

interface TabsPropsWithUrl extends TabsProps {
    tabKey?: string
    /**
     * Tabın url özelliğini kapatır.
     */
    disableUrl?: boolean
}
/**
 * @summary Tabların statlerini url query param içine kayıt eder.
 * @param disableUrl Modallarda açılan tablar için verilmeli
 */
const TabsWithUrl: React.FC<TabsPropsWithUrl> = (props) => {
    const { children, value, onChange, tabKey = "tab", disableUrl = false, ...rest } = props

    let [searchParams, setSearchParams] = useSearchParams()

    const _onChange = (event, val) => {
        if (!disableUrl)
            setSearchParams(
                {
                    [tabKey]: val,
                },
                { replace: true }
            )

        onChange && onChange(event, val)
    }

    React.useLayoutEffect(() => {
        if (!disableUrl && searchParams.get(tabKey) && value !== searchParams.get(tabKey)) {
            //TODO: Enum olan tablar ve number olan tablar sorun çıkarıyor. Bütün tablar string üzerinden çalışmalı
            const queryValue = tryParseInt(searchParams.get(tabKey), -99)
            _onChange && _onChange(undefined, queryValue === -99 ? searchParams.get(tabKey) : queryValue)
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    React.useLayoutEffect(() => {
        if (!disableUrl && searchParams.get(tabKey) && value !== searchParams.get(tabKey)) {
            //TODO: Enum olan tablar ve number olan tablar sorun çıkarıyor. Bütün tablar string üzerinden çalışmalı
            const queryValue = tryParseInt(searchParams.get(tabKey), -99)

            onChange && onChange(undefined as any, queryValue === -99 ? searchParams.get(tabKey) : queryValue)
        }
    }, [searchParams.get(tabKey)]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Tabs
            value={value}
            onChange={_onChange}
            variant="scrollable"
            scrollButtons="auto"
            allowScrollButtonsMobile
            {...rest}
        >
            {children}
        </Tabs>
    )
}

export default TabsWithUrl
