import { Box, Container } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import { AccountOff } from "mdi-material-ui"

const SignIn = () => {
    return (
        <Container>
            <CssBaseline enableColorScheme />
            <Box
                sx={(theme) => ({
                    marginTop: theme.spacing(8),
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: `${theme.spacing(2)} ${theme.spacing(3)} ${theme.spacing(3)}`,
                })}
            >
                <AccountOff
                    sx={(theme) => ({
                        margin: theme.spacing(1),
                        width: theme.spacing(7),
                        height: theme.spacing(7),
                        color: theme.palette.grey[500],
                    })}
                />
            </Box>
        </Container>
    )
}
export default SignIn
