import usePageTitle from "components/PageTitle"
import { ConfirmProvider } from "material-ui-confirm"
import React from "react"
import { Helmet } from "react-helmet-async"
import { Outlet } from "react-router-dom"

const MainContainer: React.FC = () => {
    const title = usePageTitle()
    return (
        <ConfirmProvider>
            <Helmet defer={false}>
                <title>{title}</title>
            </Helmet>
            <Outlet />
        </ConfirmProvider>
    )
}

export default MainContainer
